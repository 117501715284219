@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';
@import '../theme/css/theme';

// project specific CSS goes here


//// Preloader


.preloader {

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0b0f19;
    z-index: 5000;
  }

  &.center-container {
    display: flex;
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
  }

  .preloader-message {
    min-height: 2rem;
  }

  .preloader-spinner {
    --hue: 223;
    --bg: hsl(var(--hue), 90%, 95%);
    --fg: hsl(var(--hue), 90%, 5%);
    --trans-dur: 0.3s;
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));

    .ip {
      width: 16em;
      height: 8em;
    }

    .ip__track {
      stroke: hsl(var(--hue), 90%, 90%);
      transition: stroke var(--trans-dur);
    }

    .ip__worm1,
    .ip__worm2 {
      animation: worm1 2s linear infinite;
    }

    .ip__worm2 {
      animation-name: worm2;
    }

    /* Dark theme */
    @media (prefers-color-scheme: dark) {
      :root {
        --bg: hsl(var(--hue), 90%, 5%);
        --fg: hsl(var(--hue), 90%, 95%);
      }
      .ip__track {
        stroke: hsl(var(--hue), 90%, 15%);
      }
    }

    /* Animation */
    @keyframes worm1 {
      from {
        stroke-dashoffset: 0;
      }
      50% {
        animation-timing-function: steps(1);
        stroke-dashoffset: -358;
      }
      50.01% {
        animation-timing-function: linear;
        stroke-dashoffset: 358;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
    @keyframes worm2 {
      from {
        stroke-dashoffset: 358;
      }
      50% {
        stroke-dashoffset: 0;
      }
      to {
        stroke-dashoffset: -358;
      }
    }
  }
}

///


.socialaccount_ballot {
  text-align: center;

  ul.socialaccount_providers {
    list-style-type: none;
    font-size: 2rem;
    display: table;
    margin: 1rem auto;
    padding: 0;

    .socialaccount_provider.google {
      font-size: 0;
      display: block;
      width: 175px;
      height: 40px;
      background-image: url("../images/sign_in_with_google.svg");
    }
  }

  .login-or {
    margin: 1.5rem;
  }
}

///////////////


///////////

.navbar {

  .app-logo {
    width: 1.7rem;
    height: 1.7rem;
  }
}


////////////////


a.link-no-decoration, a.link-no-decoration:hover, a.link-no-decoration:visited, a.link-no-decoration:active {
  text-decoration: none;
  color: inherit;
}

a.link-no-underline, a.link-no-underline:hover, a.link-no-underline:visited, a.link-no-underline:active {
  text-decoration: none;
}

.btn-run-recipe {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  min-width: 150px;
}

.recipe-detail-page {

  h1 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .recipe-detail-thumbnail {
    margin: auto;

  }

  .recipe-info-panel, .recipe-sing-in-panel {
    margin-top: calc(2rem + 5px);
  }

  .run-recipe-form {
    .form-label {
      font-size: 1.2rem;
    }
  }


}

.recipe-list {

  .recipe-list-container {
    justify-content: center;
  }

  &.justify-recipes {
    .recipe-list-container {
      justify-content: space-between;
    }

    @include media-breakpoint-down(sm) {
      .recipe-list-container {
        justify-content: center;
      }
    }
  }
}


.recipe-list-card {
  width: 240px;
  min-width: 240px;
  max-width: 240px;


  margin: 15px;

  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0 0 30px 5px #6366f1;
  }

  .draft-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1000;
  }


  .recipe-card-thumbnail-container {
    $recipe-card-thumbnail-size: 238px;

    max-height: $recipe-card-thumbnail-size;
    max-width: $recipe-card-thumbnail-size;
    min-height: $recipe-card-thumbnail-size;
    overflow: hidden;
    position: relative;


    .recipe-card-thumbnail {
      max-height: $recipe-card-thumbnail-size;
      max-width: $recipe-card-thumbnail-size;
      min-height: $recipe-card-thumbnail-size;
      object-fit: cover;
    }

    .animated-thumbnail-overlay {
      display: none;
      position: absolute;
      top: 33%;
      left: 43%;
      font-size: 3rem;

      text-shadow: 0 0 10px #000000;
    }

    &.recipe-card-with-animation {
      .animated-thumbnail-overlay {
        display: unset;
      }

      &:hover {
        .animated-thumbnail-overlay {
          display: none;
        }

        .recipe-card-thumbnail-static {
          display: none;
        }

        .recipe-card-thumbnail-animated {
          display: unset;
        }
      }
    }


    .recipe-tags-container {
      position: absolute;
      bottom: 62px;
      right: 2px;
      font-size: 0.8em;
    }

  }


  .card-body {
    padding: 0.2em 3.6em 0em 0.5em;
    min-width: 240px;
    max-width: 240px;
    height: 3em;
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: rgba(0, 0, 0, 0.7);

    .card-title {
      margin-bottom: 0em;
      max-height: 1.2em;
      overflow: hidden;
      font-size: 0.65rem;
      line-height: 1.2;
      font-weight: bold;
    }

    .card-footer {
      padding: 0em;
      margin: 0em;
      margin-top: 0.1em;
      border-top: none;
      white-space: nowrap;
      vertical-align: middle;
      font-size: 0.55em;

      .username {
        min-width: 3.8rem;
        max-width: 3.8rem;
        text-overflow: clip;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 0.5rem;
      }
    }

    .run-button-pill {
      position: absolute;
      right: 8px;
      bottom: 1em;
      background-color: #3B3B3B;
      font-weight: bold;
      border-radius: 12px;
      height: 24px;
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: center;
      align-items: center;
      font-size: 1.2em;

    }

    .recipe-card-stat-icon {
      margin-right: 0.15rem;
    }
  }


}


/////////////////////////////////////////


.create-recipe-page {

  .recipe-kind-button {
    display: inline-block;
    min-width: 22rem;

    .recipe-kind-button-header {
      font-size: 3rem;
    }

    .recipe-kind-button-description {
      font-size: 1.2rem;
    }
  }

}


/////////////

.large-form-text {
  font-size: 0.9rem !important;
}


/////////////


// fix for dark mode theme bug
.form-select:not([data-bs-theme=light]) option {
  background-color: #0b0f19;
}

@media (prefers-reduced-motion: reduce) {
  // overriding bootstrap's default animation to always show it
  .progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes !important;
  }
}


.preformatted {
  white-space: pre-wrap;
}


////////////

.comfy-editor-progress-bar {
  height: 0.5rem !important;
}


//////////


.recipe-action-button-icon {
  font-size: 1.2rem;
}

.front-page {
  .contest-block {
    min-height: 350px;

    background-image: url("../images/contest1/back.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    .contest-header {
      text-shadow: #000000 0 0 5px;

    }
  }
}


//////////////// typing dots animation


.typing-dots {
  $dot-width: 0.55rem;
  $dot-color: #3b5998;
  $speed: 1.5s;

  position: relative;
  display: inline-block;
  height: $dot-width;
  width: $dot-width*6;

  span {
    content: '';
    animation: typing-dots-blink $speed infinite;
    animation-fill-mode: both;
    height: $dot-width;
    width: $dot-width;
    background: $dot-color;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: .2s;
      margin-left: $dot-width * 1.5;
    }

    &:nth-child(3) {
      animation-delay: .4s;
      margin-left: $dot-width * 3;
    }
  }

  @keyframes typing-dots-blink {
    0% {
      opacity: .1;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .1;
    }
  }

}


.animated-ellipsis {
  font-size: 40px;

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 1000ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
}

//////

.slow-blinking {
  animation: slow-blinker 2s linear infinite;
  @keyframes slow-blinker {
    50% {
      opacity: 0.4;
    }
  }
}


//////////

@mixin full-width-height {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}


.compare-component {
  @include full-width-height;

  .compare-container {
    @include full-width-height;
  }

  .slider-mode {
    > div {
      @include full-width-height;
    }

  }

  .buttons-mode {
    display: flex;
    flex-direction: column;

    .image-container {
      @include full-width-height;
      position: relative;


      img {
        width: 100%;
        height: 170px;
        object-fit: cover;
      }

    }
  }


  .diagram-mode {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    .before-block, .after-block {
      flex: 1;
      height: 100%;
      max-height: 200px;
      max-width: 200px;

      img {

        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .before-block {
      padding-right: 10px;
    }


  }

  .picture-in-picture-mode {

    width: 400px;
    height: 400px;

    position: relative;

    .before-block, .after-block {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .after-block {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }

    .before-block {
      position: absolute;
      border: #0a53be 2px solid;

      left: 0;
      bottom: 0;
      height: 50px;
      width: auto;
      z-index: 200;
    }
  }

}


.recipe-param {
  & img {
  }

}

$recipe-io-image-size: 200px;

.recipe-parameter-viewer {

  height: $recipe-io-image-size;
  width: auto;
  max-width: $recipe-io-image-size;

  .recipe-parameter-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .recipe-parameter-text {
    width: 100%;
    height: 100%;
    padding: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }
}

.recipe-starter {
  .recipe-parameter {
    .form-label {
      font-size: 1.2rem;
    }

    .uppy-dashboard {
    }
  }

  .uppy-Root {
    .uppy-Dashboard-AddFiles {
      border: none;

      .uppy-Dashboard-AddFiles-title {
        padding-top: 100px;
      }
    }

    .uppy-DashboardContent-bar {
      display: none;
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
}

.recipe-expandable-list {
  // offsetting recipe glow border
  margin-left: -15px;

  .expand-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    .expand-button {
      z-index: 100;
      background-color: var(--bs-secondary-border-subtle);

      &:active {
        background-color: var(--bs-secondary-border-subtle);
      }
    }

    .expand-separator {
      position: absolute;
      top: 50%;
      left: 1rem;
      right: 1rem;
      height: 1px;
      background-color: var(--bs-secondary-border-subtle);
      z-index: 50;
    }
  }

}

.recipe-detail-v2 {

  .recipe-header-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    h1 {
      margin-bottom: 0;
    }

    margin-bottom: 0.5rem;
  }

  .before-after {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @include media-breakpoint-down(md) {
      & {
        gap: 0.4rem;
      }
    }

    .recipe-inputs-outputs-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      @include media-breakpoint-down(md) {
        & {
          gap: 0.4rem;
        }
      }

      .recipe-input-output {
      }

      .recipe-input-output-divider {
        font-size: 2rem;
        margin: auto;
      }
    }

    .recipe-transformation-divider {
      font-size: 2.5rem;
      margin: auto;
    }

    .legacy-thumbnail-container {
      .legacy-thumbnail {
        max-height: 320px;
        width: auto;
      }
    }

    .flex-filler {
      flex-grow: 1;
    }
  }

  .recipe-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;

    .recipe-info-bar {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: wrap;
      align-items: center;

      .user-info {
        flex-grow: 1;

        display: flex;
        flex-direction: row;
        align-items: center;

        .user-avatar {
          font-size: 1.7rem;

        }
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem 0.5rem;
      flex-wrap: wrap;

      .action-button {
        padding-right: 2px;
        padding-left: 2px;
      }
    }
  }

  .recipe-detail-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      & {
        align-items: center;

        .recipe-list-container {
          align-items: center;
          justify-content: center;
        }
      }
    }

  }

  .comfy-metadata {
    .model-list, .node-list {
      display: flex;
      flex-direction: row;
      gap: 0.5rem 0.5rem;
      flex-wrap: wrap;
    }
  }
}


.page-navigation {

  .app-logo {
    height: 1.75rem;
    width: auto;
    object-fit: contain;
  }
}

.app-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 40px);
  align-items: stretch;

  .page-content {
    flex-grow: 1;
  }

  .page-footer {
    flex-shrink: 0;
  }
}


.page-background {
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;

  &.page-background-1 {
    background-image: url("../images/backgrounds/page-background-1.png");
  }

  &.page-background-2 {
    background-image: url("../images/backgrounds/page-background-2.png");
  }

  &.page-background-3 {
    background-image: url("../images/backgrounds/page-background-3.png");
  }

  &.page-background-4 {
    background-image: url("../images/backgrounds/page-background-4.png");
  }

  &.page-background-5 {
    background-image: url("../images/backgrounds/page-background-5.png");
  }
}


.image-loading-background {
  background: transparent url("../images/backgrounds/loading-background.png") center no-repeat;
  background-size: contain;
}

.recipe-result-v2 {
  .recipe-result-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .app-logo-container {
      flex-shrink: 0;

      .app-logo {
        height: 1.75rem;
        width: auto;
      }
    }


    .recipe-name {
      font-size: 1.25rem;
      margin: 0;

      @include media-breakpoint-down(md) {
        & {
          font-size: 1rem;
        }
      }
    }

  }

  .recipe-result-viewer {
    @include media-breakpoint-up(md) {
      & {
        min-height: 80vh;
      }
    }


    .recipe-result-content-viewer {
      text-align: center;

      .recipe-parameter-image {
        max-height: 80vh;
        max-width: 90vw;
        object-fit: contain;

        @include media-breakpoint-down(lg) {
          & {
            width: 90vw;
            height: auto;
          }
        }
      }
    }

    .side-by-side-viewer {
      .recipe-result-side {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;

        @include media-breakpoint-down(lg) {
          & {
            gap: 1rem;
            align-items: center;
          }
        }

      }

      .recipe-parameter-image {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .slider-viewer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .compare-slider {

        max-height: 80vh;
        max-width: 90vw;

        width: min(80vh, 80vw);
        height: min(80vh, 80vw);

      }
    }


  }


  .recipe-result-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .user-info {
      flex-grow: 1;

      display: flex;
      flex-direction: row;
      align-items: center;

      .user-avatar {
        font-size: 1.7rem;

      }
    }

    .action-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 0.75rem 0.5rem;
      justify-content: center;
      flex-wrap: wrap;
    }
  }


  .creator-tools-block {
    .block-title {
      font-size: 1rem;
      text-align: center;
    }

    .creators-toolbar {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .toolbar-title {
        font-size: 1rem;

        .toolbar-subtitle {
          font-size: 0.75rem;
        }
      }

      .action-buttons {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }
    }

  }
}

.sh-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.75rem;
}


.signin-modal {
  .modal-content {
    border-color: var(--bs-success);
    border-width: 2px;

    .modal-header {
      justify-content: center;
    }

    .modal-body {
      display: flex;
      justify-content: center;
    }
  }

}


.user-profile-page {

  .profile-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    h1 {
      margin-bottom: 0;
      @include media-breakpoint-down(lg) {
        & {
          font-size: 1.5rem;
        }
      }

    }

    .edit-button {
      font-size: 1.5rem;
    }

  }

  .profile-stats {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  .recipe-tabs {
    .nav-tabs {
      display: flex;
      justify-content: center;
      flex-direction: row;
    }

  }

}


.comment {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;

  .comment-logo {
    font-size: 2rem;
  }

  .comment-body {
    flex-grow: 1;

    .comment-info {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      .username {
        font-weight: bold;
      }
    }
  }

  .comment-buttons {
    display: flex;
    gap: 0.5rem;

    .dropdown {
      button {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
      }
    }
  }


  &.comment-view {
    .comment-buttons {
      visibility: hidden;
    }
  }

  &.comment-view:hover {
    .comment-buttons {
      visibility: visible;
    }
  }
}


.bonus-credits-dialog {
  .credit-value {
    font-weight: bold;
    color: #168118;
  }

}


.simple-recipe-editor {

  .action-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
  }

}
